import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import AudioItem from '../components/audioitem'
import VideoItem from '../components/videoitem'
import { Row, Col } from "react-bootstrap";
const IndexPage = () => {

return (
  <Layout>
    <Col>
      <Row><Col><h2>csound-web instruments</h2></Col></Row>
      <Row><Col><a href="/random-music/index.html">random-music</a></Col><Col>Generation of random electro music or random drones</Col></Row>
      <Row><Col><a href="/waveterrain/index.html">wave terrain</a></Col><Col>Demonstration of the <a target="_blank" href="https://csound.com/docs/manual/wterrain2.html">wterrain2</a> opcode of Csound</Col></Row>
      <Row><Col><a href="/superterrain/index.html">super terrain</a></Col><Col>Demonstration of the <a  target="_blank" href="https://csound.com/docs/manual/sterrain.html">sterrain</a> opcode of Csound</Col></Row>
      <Row><Col><a href="/geneticterrain/index.html">genetic terrain</a></Col><Col>Wave terrain sysnthesis with complex terrains</Col></Row>
      <Row><Col><a href="/geneticsuperterrain/index.html">genetic super terrain</a></Col><Col>Wave terrain sysnthesis with complex terrains and superformula curves</Col></Row>
      <Row><Col><a href="/randomsplinewaves/index.html">random spline waves</a></Col><Col>Synthesis with random splines</Col></Row>
      <Row><Col><h5> </h5></Col></Row>
      <Row><Col><h2>Random </h2></Col></Row>
      <Row><Col><AudioItem dir="random" name="rnd05" title="random symphony"/></Col></Row>
      <Row><Col><AudioItem dir="random" name="rnd03" title="lost in space"/></Col></Row>
      <Row><Col><AudioItem dir="random" name="rnd02" title="mystery"/></Col></Row>
      <Row><Col><AudioItem dir="random" name="rnd01" title="windspiel"/></Col></Row>
      <Row><Col><VideoItem name="vanished" title="vanished"/></Col></Row>
      <Row><Col>&nbsp;</Col></Row>
      <Row><Col><h2>hypnosis</h2></Col></Row>
      <Row><Col><AudioItem dir="hypnosis" name="hyp09" title="hyp09"/></Col></Row>
      <Row><Col><AudioItem dir="hypnosis" name="hyp08" title="hyp08"/></Col></Row>
      <Row><Col><AudioItem dir="hypnosis" name="hyp07" title="hyp07"/></Col></Row>
      <Row><Col><AudioItem dir="hypnosis" name="hyp06" title="hyp06"/></Col></Row>
      <Row><Col><AudioItem dir="hypnosis" name="hyp05" title="hyp05"/></Col></Row>
      <Row><Col><AudioItem dir="hypnosis" name="hyp04" title="hyp04"/></Col></Row>
      <Row><Col><AudioItem dir="hypnosis" name="hyp03" title="hyp03"/></Col></Row>
      <Row><Col><AudioItem dir="hypnosis" name="hyp02" title="hyp02"/></Col></Row>
      <Row><Col><AudioItem dir="hypnosis" name="hyp01" title="hyp01"/></Col></Row>
      <Row><Col><AudioItem dir="hypnosis" name="hyp00" title="hyp00"/></Col></Row>
      <Row><Col><h2>infinity</h2></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo1" title="oo-1"/></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo2" title="oo-2"/></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo3" title="oo-3"/></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo4" title="oo-4"/></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo5" title="oo-5"/></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo6" title="oo-6"/></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo7" title="oo-7"/></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo8" title="oo-8"/></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo9" title="oo-9"/></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo10" title="oo-10"/></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo11" title="oo-11"/></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo12" title="oo-12"/></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo13" title="oo-13"/></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo14" title="oo-14"/></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo15" title="oo-15"/></Col></Row>
      <Row><Col><AudioItem dir="infinity" name="oo16" title="oo-16"/></Col></Row>
      <Row><Col><VideoItem name="escaped" title="escaped"/></Col></Row>
    </Col>
  </Layout>
)
}

export default IndexPage
