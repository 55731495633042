import React from 'react'
import { Row,Col} from 'react-bootstrap'
class AudioItem extends React.Component {
  render() {
    let path = '/' + this.props.dir + '/' + this.props.name + '.mp3';
    return (
        <Row>
           <Col md={3}>{this.props.title}</Col><Col md={9}><audio controls src={path} preload="metadata">
            Your browser does not support the
            <code>audio</code> element.
           </audio></Col>
        </Row>
    );
  }
}

export default AudioItem
