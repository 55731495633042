import React from 'react'
import { Player,ControlBar } from 'video-react';
class VideoItem extends React.Component {
  render() {
    let path = '/videos/' + this.props.name + '/' + this.props.name;
    let poster = path + '_poster.jpg';
    let vid1080 = path + '_1080p.mp4';
    let vid720 = path + '_720p.mp4';
    let vid540 = path + '_540p.mp4';
    return (
      <div>
        <h2>{this.props.title}</h2>
        <div>{this.props.children}</div>
        <Player poster={poster} src={vid1080}>
           <ControlBar autoHide={false} className="my-class" />
        </Player>
      </div>
    );

  }
}

export default VideoItem

